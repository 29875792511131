.select-user-tabs.nav-tabs {
  border-bottom: 0 !important;
  .nav-item {
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    flex: 1;
  }
  .nav-link {
    position: relative;
    background: $info !important;
    color: rgba($white, 0.6) !important;
    font-weight: 400;
    border: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 20px;
    text-transform: uppercase;
    &:hover {
      color: rgba($white, 0.6);
    }
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: rgba($white, 1) !important;
    background: $info !important;
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: 10px;
      border-top-color: $info;
      content: "";
      pointer-events: none;
    }
  }
}

.apexcharts-text {
  fill: $text-muted;
}
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line {
  stroke: rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}
