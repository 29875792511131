/*******************/
/*Steam line widget*/
/*******************/
.steamline {
  position: relative;
  border-left: 1px solid $border-color;
  margin-left: 20px;
  .sl-left {
    float: $lft;
    margin-left: -20px;
    z-index: 1;
    width: 40px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    border-radius: 100%;
    color: $white;

    margin-right: 15px;
    img {
      max-width: 40px;
    }
  }
  .sl-right {
    padding-left: 30px;
    .desc,
    .inline-photos {
      margin-bottom: 21px;
    }
  }
  .sl-item {
    border-bottom: 1px solid $border-color;
    margin: 20px 0;
    &:last-child {
      border-bottom: none;
    }
  }
}

.sl-date {
  font-size: 10px;
  color: $gray-500;
}

.time-item {
  border-color: $border-color;
  padding-bottom: 1px;
  position: relative;
  &:before {
    content: ' ';
    display: table;
  }
  &:after {
    background-color: $white;
    border-color: $border-color;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
  }
}

.time-item-item {
  &:after {
    content: ' ';
    display: table;
  }
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
  p {
    margin-bottom: 10px !important;
  }
}
