/*
/*******************
User card page
******************/
.el-element-overlay {
  .white-box {
    padding: 0px;
  }
  .el-card-item {
    position: relative;
    padding-bottom: 20px;
    .el-card-avatar {
      margin-bottom: 20px;
    }
    .el-card-content {
      text-align: center;
    }
    .el-overlay-1 {
      width: 100%;
      overflow: hidden;
      position: relative;
      text-align: center;
      cursor: default;
      img {
        display: block;
        position: relative;
        -webkit-transition: all 0.4s linear;
        transition: all 0.4s linear;
        width: 100%;
        height: auto;
      }
      &:hover img {
        -ms-transform: scale(1.2) translateZ(0);
        -webkit-transform: scale(1.2) translateZ(0);
        transform: scale(1.2) translateZ(0);
        -o-transform: scale(1.2) translateZ(0);
        -moz-transform: scale(1.2) translateZ(0);
      }
      .el-info {
        text-decoration: none;
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        background-color: transparent;
        filter: alpha(opacity=0);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        padding: 0;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%) translateZ(0);
        -webkit-transform: translateY(-50%) translateZ(0);
        -ms-transform: translateY(-50%) translateZ(0);
        -o-transform: translateY(-50%) translateZ(0);
        -moz-transform: translateY(-50%) translateZ(0);
        .el-item {
          list-style: none;
          display: inline-block;
          margin: 0 3px;
          .el-link {
            border-color: $white;
            color: $white;
            padding: 12px 15px 10px;
            &:hover {
              background: $themecolor;
              border-color: $themecolor;
            }
          }
        }
      }
    }
    .el-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
    .el-overlay-1:hover .el-overlay {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
    }
    .el-overlay-1 {
      .scrl-dwn {
        top: -100%;
      }
      .scrl-up {
        top: 100%;
        height: 0px;
      }
      &:hover .scrl-dwn {
        top: 0px;
      }
      *:hover .scrl-up {
        top: 0px;
        height: 100%;
      }
    }
  }
}
